/* eslint-disable no-unused-vars */
/* eslint-disable space-before-function-paren */
import { Client } from "interfaces/client";
import { ClientService } from "interfaces/services";
import moment from "moment";
import { Option } from "interfaces/option";

export const clientsHaveActivatedServices = (
	clients: Client[],
	selected: string[]
): string | null => {
	for (const client of clients) {
		if (
			selected.includes(client.uuid) &&
			client.clientService.length &&
			areApiActive(client.clientService)
		) {
			return client.name;
		}
	}
	return null;
};

export const randomKeyGen = () => {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < 15; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
	}
	return result;
};

export const convertToMo = (data: number) => {
	return `${Number(data / 1000000).toFixed()} Mo`;
};

export const uniqueValues = <T,>(arr: Array<T>) => {
	return Array.from(new Set(arr)).filter((e) => e);
};

export const getServicesTypes = (client: Client) => {
	const servicesTypes: Array<string> = client.clientService.map((service) => {
		if (service.service.searchKey.includes("_SDK")) {
			return "SDK";
		} else if (service.service.searchKey.includes("_PREMISE")) {
			return "On Premise";
		} else {
			return "API";
		}
	});

	if (client.licenses && client.licenses.length > 0)
		servicesTypes.push("Plugin");

	return uniqueValues(servicesTypes);
};

export const isServiceActivated = (services: ClientService[], key: string) => {
	return services?.some((e: ClientService) => e?.service?.searchKey === key);
};

export const getServiceByKey = (services: ClientService[], key: string) => {
	return services?.find((e: ClientService) => e?.service?.searchKey === key);
};

export const generateArrayOfYears = () => {
	const years = [];

	const initialYear = moment(2021, "YYYY");
	const currentYearPlusFour = moment().add(5, "year");
	const diff = moment(currentYearPlusFour).diff(initialYear, "years");
	for (let i = 2021; i < 2021 + diff; i++) {
		years.push(`${i}`);
	}
	return years;
};

export const generateArrayOfYearsAndMonths = (yearStart: string): string[] => {
	const result: string[] = [];
	const currentYearAndMonth = moment().format("YYYY-MM");
	const difference = moment(currentYearAndMonth).diff(yearStart, "years");
	const months = moment.months();
	for (let i = 0; i <= difference; i++) {
		const year = moment(yearStart).add(i, "y");
		months.forEach((_, index) => {
			const month = moment(year).add(index, "M");
			if (month.isBefore(moment())) {
				result.push(month.format("YYYY-MM"));
			}
		});
	}
	return result;
};

export const convertSize = (a: number, b?: number) => {
	if (a === 0) {
		return "0 Byte";
	}
	const c = 1024;
	const d = b || 2;
	const e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const f = Math.floor(Math.log(a) / Math.log(c));

	return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const getMegaByteValue = (a: number, b?: number): number => {
	if (a === 0) {
		return 0;
	}

	const c = 1024;
	const d = b || 2;

	return parseFloat((a / (c * c)).toFixed(d));
};

export const getByteValue = (a: number, b?: number): number => {
	if (a === 0) {
		return 0;
	}

	const c = 1024;
	const d = b || 2;
	const f = Math.floor(Math.log(a) / Math.log(c));

	return parseFloat((a / Math.pow(c, f)).toFixed(d));
};

export const getByteSize = (a: number): string => {
	if (a === 0) {
		return "Byte";
	}

	const c = 1024;
	const e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const f = Math.floor(Math.log(a) / Math.log(c));
	return e[f];
};

export const getAllApi = (services: any) => {
	return services?.filter(
		(service: any) =>
			!service.name.includes("SDK") &&
			!service.name.includes("On premise")
	);
};

export const areApiActive = (services: ClientService[]) => {
	return services?.some(
		(e: ClientService) =>
			!e?.service?.searchKey?.includes("SDK") &&
			!e?.service?.searchKey?.includes("PREMISE") &&
			!e.serviceEnd
	);
};

export const equals = (a: any, b: any) => {
	return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
};

export const sleep = (ms: number) =>
	new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Usefull to check if each object in array contains a specific value for a specific key
 * @param arr Array of objects
 * @param key Key in array of objects
 * @param value Value to find in every item for given key
 * @returns true if each item at given key contains given value, false otherwise
 */
export const checkEveryValueInObjectArray = (
	arr: Array<any>,
	key: string,
	value: number | string
) => arr.every((item) => item[key] === value);

/**
 * Usefull to check if at least one object in array contains a specific value for a specific key
 * @param arr Array of objects
 * @param key Key in array of objects
 * @param value Value to find in at least one item for given key
 * @returns true if at least one item at given key contains given value, false otherwise
 */
export const checkSomeValueInObjectArray = (
	arr: Array<any>,
	key: string,
	value: number | string
) => arr.some((item) => item[key] === value);

/**
 * Usefull to check if at least one object in array does not contains a specific value for a specific key
 * @param arr Array of objects
 * @param key Key in array of objects
 * @param value Value to find in at least one item for given key
 * @returns true if at least one item at given key contains given value, false otherwise
 */
export const checkSomeValueNotInObjectArray = (
	arr: Array<any>,
	key: string,
	value: number | string
) => arr.some((item) => item[key] !== value);

export const yearlyOptions = (): Option[] => {
	const options: Option[] = [];

	for (let i = moment().year(); i > 2019; i--) {
		options.push({ label: i.toString(), value: i.toString() });
	}

	return options;
};

export const monthlyOptions = (): Option[] => {
	const options: Option[] = moment.months().map((month) => ({
		label: month,
		value: moment().month(month).format("MM"),
	}));

	return options;
};

export const capitalize = (str: string) => {
	// Check if the string is empty
	if (str.length === 0) {
		return str;
	}
	// Capitalize the first character and append the rest of the string
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
