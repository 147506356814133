import { Option } from "interfaces/option";
import React, { useState } from "react";

type Period = {
  dateStart: string;
  dateEnd: string;
};

enum ServicesNames {
  "SRV_ANM" = "Anonymization",
  "SRV_CNG" = "Congestion",
  "SRV_FACE" = "Faces attributes",
  "SRV_IDENTITY" = "Face recognition",
  "SRV_ORIENTATION" = "Orientation",
  "SRV_SOL" = "Soiling",
  "SRV_VHC_PDS_DETECT" = "Vehicules and pedestrians",
  "SRV_WATERMARK" = "Watermark",
  "SRV_FAKE_DETECTION" = "Fake detection",
}

export type SelectServiceOption = {
  value: keyof typeof ServicesNames | "" | undefined | string;
  label: ServicesNames | "";
};

export function useDashboardConsumptionFilter() {
  const [periods, setPeriods] = useState<Period>({
    dateStart: "",
    dateEnd: "",
  });
  const [serviceSelected, setServiceSelected] = useState<SelectServiceOption>({
    label: "",
    value: "",
  });

  const services = Object.keys(ServicesNames).map((d) => ({
    value: d as keyof typeof ServicesNames,
    label: ServicesNames[d as keyof typeof ServicesNames],
  }));
  services.splice(0, 0, {
    value: undefined as unknown as keyof typeof ServicesNames,
    label: "ALL" as ServicesNames,
  });

  const handleSelectService = (data: SelectServiceOption) => {
    setServiceSelected(data);
  };

  const handleSelectPeriod = (data: Period, key: keyof Period) => {
    setPeriods({
      ...periods,
      [key]: data,
    });
  };

  return {
    periods,
    services,
    serviceSelected,
    handleSelectService,
    handleSelectPeriod,
  };
}
