import { Client } from 'interfaces/client';
import { PaginationResponse } from 'interfaces/pagination';
import React, { createContext, useReducer } from 'react';

interface ClientManagementSort {
    sortBy: string,
    sortOrder: 'ASC' | 'DESC'
};

export interface ClientManagementFilter {
    name?: string | undefined,
    support?: string[],
    service?: string[], // SDK / API / ON PREMISE
    startingDate?: string,
    endingDate?: string,
    monthlyConsummer?: string[]
};

// TODO terminer le typage

interface ClientManagement {
    responseClient: PaginationResponse<Client[]> | null,
    responseService: any[] | null,
    responseSupports: any[] | null,
    isLoading: false,
    isError: false,
    error: any,
    sort: ClientManagementSort,
    filter?: ClientManagementFilter | undefined
    paginationInput: {
        page: number,
        elementPerPage: number
    }
};

const initialValues: ClientManagement = {
    responseClient: null,
    responseService: null,
    responseSupports: null,
    isLoading: false,
    isError: false,
    error: null,
    sort: {
        sortBy: 'mail',
        sortOrder: 'DESC'
    },
    paginationInput: {
        page: 1,
        elementPerPage: 10
    }
};

const clientManagementContext = createContext<{ state: ClientManagement, dispatch: React.Dispatch<any> }>({ state: initialValues, dispatch: () => { } });

const reducer = (state: ClientManagement, action: any): ClientManagement => {
    const { type, target } = action;
    switch (type) {
        case 'SET_LOADING': {
            return {
                ...state,
                isLoading: target
            };
        }
        case 'SET_RESPONSE_CLIENT': {
            return {
                ...state,
                responseClient: target
            };
        }
        case 'SET_IS_ERROR': {
            return {
                ...state,
                isError: target
            };
        }
        case 'SET_ERROR': {
            return {
                ...state,
                error: target
            };
        }
        case 'SET_SORT': {
            return {
                ...state,
                sort: target
            };
        }
        case 'SET_PAGINATION_INPUT': {
            return {
                ...state,
                paginationInput: target
            };
        }
        case 'SET_FILTERS': {
            return {
                ...state,
                filter: target
            };
        }
        default: return state;
    }
};
const clientManagementProvider: React.FC = ({
    children
}) => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    return (
        <clientManagementContext.Provider value={{ state, dispatch }}>
            {children}
        </clientManagementContext.Provider>
    );
};

export { clientManagementContext, clientManagementProvider };
