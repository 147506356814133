import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import {
  CallsPerPeriod,
  ConsumptionData,
  YearlyAggregatesCalendarData,
  TotalAggregates,
  MonthlyAggregates,
} from "context/consumptionContext";
import {
  ClientService,
  Service,
  ClientServiceConfig,
} from "interfaces/services";
import {
  AccountSerie,
  ClientCountDateElement,
  ClientCountElement,
  ConsumptionCount,
  PieChartSerie,
  Plans,
  ConsumptionByService,
  ConsumptionByPeriod,
  ConsumptionSerie,
  HeatMapSerie,
  AggregationConsumption,
  ClientAggregationConsumption,
  ConsumptionCell,
} from "interfaces/client";
import { Account } from "context/dashboardClientsContext";
import { convertSize, getMegaByteValue } from "utils/utils";

export const listApiServices: { [name: string]: string } = {
  anonymization: "SRV_ANM",
  blurIt: "SRV_ANM",
  congestion: "SRV_CNG",
  facelytics: "SRV_FACE",
  soiling: "SRV_SOL",
  watermark: "SRV_WATERMARK",
  orientation: "SRV_ORIENTATION",
  vehiclesAndPedestriansDetection: "SRV_VHC_PDS_DETECT",
  identity: "SRV_IDENTITY",
  fakeDetection: "SRV_FAKE_DETECTION",
};

export const servicesAPIKey: { [name: string]: string } = {
  blurIt: "SRV_ANM",
  facelytics: "SRV_FACE",
  congestion: "SRV_CNG",
  soiling: "SRV_SOL",
  watermark: "SRV_WATERMARK",
  orientation: "SRV_ORIENTATION",
  vehiclesAndPedestriansDetection: "SRV_VHC_PDS_DETECT",
  identity: "SRV_IDENTITY",
  fakeDetection: "SRV_FAKE_DETECTION",
};

export const servicesColors: { [name: string]: string } = {
  SRV_ANM: "#42D07D",
  SRV_CNG: "#F50A6B",
  SRV_FACE: "#00A2FF",
  SRV_IDENTITY: "#004D80",
  SRV_ORIENTATION: "#D5D5D5",
  SRV_SOL: "#AA7942",
  SRV_VHC_PDS_DETECT: "#BC0551",
  SRV_WATERMARK: "#FED340",
  SRV_FAKE_DETECTION: "#C643FE",
};

export const servicesConfig = {
  blurIt: {
    SRV_ANM: ({} as ClientService) || null, // api
    SRV_BLIT_SDK: ({} as ClientService) || null, // sdk
    SRV_BLIT_PREMISE: ({} as ClientService) || null, // premise
  },
  facelytics: {
    SRV_FACE: ({} as ClientService) || null, // api
    SRV_FACE_SDK: ({} as ClientService) || null, // sdk
  },
  congestion: {
    SRV_CNG: ({} as ClientService) || null, // api
  },
  soiling: {
    SRV_SOL: ({} as ClientService) || null, // api
  },
  watermark: {
    SRV_WATERMARK: ({} as ClientService) || null, // api
  },
  orientation: {
    SRV_ORIENTATION: ({} as ClientService) || null, // api
  },
  vehiclesAndPedestriansDetection: {
    SRV_VHC_PDS_DETECT: ({} as ClientService) || null, // api
  },
  identity: {} as { [key: string]: ClientService | null },
  fakeDetection: {
    SRV_FAKE_DETECTION: ({} as ClientService) || null, // api
  },
};

const _getData2PerDateAndType = (
  date: string,
  type: string,
  array: CallsPerPeriod[]
) => {
  const result = { size: 0, nbCall: 0 };
  array.forEach((elem) => {
    if (elem.date === date && elem.type === type) {
      result.size += parseInt(elem.size);
      result.nbCall += parseInt(elem.totalCount);
    }
  });
  return result;
};

export const optionsServices = [
  "pic_only",
  "pic_coord",
  "vid_only",
  "vid_coord",
];

const _setPremiseServicesFormat = (userServicesActivated?: string[]) => {
  return optionsServices
    .filter((option) =>
      userServicesActivated?.find((service) => service === option)
    )
    .map((option) => ({
      label: option,
      value: option,
      active: true,
    }));
};

const _formatServiceBySearchKey = (
  toFind: string,
  clientServices: ClientService[],
  allServices: Service[]
) => {
  const typeOfService = toFind.includes("SDK")
    ? "SDK"
    : toFind.includes("PREMISE")
    ? "PREMISE"
    : "API";

  let config: ClientServiceConfig = {
    end: moment().format("DD/MM/YYYY"),
    start: moment().format("DD/MM/YYYY"),
    machineId: null,
    uuid: null,
    name: null,
    services: [],
    isActive: false,
    searchKey: null,
    support: null,
    sdkType: null,
    OPsetup: null,
    OPLicenceType: null,
    typeOfService,
    title: typeOfService,
    licenceFilePath: null,
  };

  const deactivatedService = allServices.find(
    (service) => service.searchKey === toFind
  );
  config = {
    ...config,
    searchKey: deactivatedService?.searchKey || null,
    uuid: deactivatedService?.uuid,
    name: deactivatedService?.name,
  };

  const activatedService = clientServices.find(
    (service) => service?.service?.searchKey === toFind && !service.serviceEnd
  );
  if (activatedService) {
    config = {
      ...config,
      end: activatedService?.end
        ? moment(activatedService.end).format("DD/MM/YYYY")
        : null,
      start: moment(activatedService.start).format("DD/MM/YYYY"),
      machineId: activatedService?.machineId,
      uuid: activatedService?.service?.uuid,
      name: activatedService?.service?.name,
      support: activatedService.support,
      sdkType: activatedService.sdkType,
      OPsetup: activatedService.OPsetup,
      OPLicenceType: activatedService.OPLicenceType,
      services: toFind.includes("PREMISE")
        ? (_setPremiseServicesFormat(activatedService?.services) as any)
        : [],
      isActive: true,
      searchKey: activatedService?.service?.searchKey,
    };
  }

  return config;
};

const _formatServiceIdentityConsumption = (
  clientServices: ClientService[],
  allServices: Service[]
) => {
  let config = {
    end: moment().format("DD/MM/YYYY"),
    start: moment().format("DD/MM/YYYY"),
    machineId: null,
    uuid: null,
    name: null,
    services: [],
    isActive: false,
    searchKey: null,
    support: null,
    sdkType: null,
    OPsetup: null,
    OPLicenceType: null,
    typeOfService: "API",
    title: null,
  } as any;

  const identitiesServices = ["UPSERT", "SEARCH", "RECOGNIZE"];
  let identityGroup: { [key: string]: any } = {};

  for (const serviceIdentity of identitiesServices) {
    const deactivatedService = allServices.find(
      (service) => service.searchKey === "SRV_IDENTITY"
    );
    config = {
      ...config,
      searchKey: deactivatedService?.searchKey || null,
      uuid: deactivatedService?.uuid,
      name: deactivatedService?.name,
      title: serviceIdentity,
    };

    const activatedService = clientServices.find(
      (service) => service?.service?.searchKey === "SRV_IDENTITY"
    );
    if (activatedService) {
      config = {
        ...config,
        end: activatedService?.end
          ? moment(activatedService.end).format("DD/MM/YYYY")
          : null,
        start: moment(activatedService.start).format("DD/MM/YYYY"),
        machineId: activatedService?.machineId,
        uuid: activatedService?.service?.uuid,
        name: activatedService?.service?.name,
        support: activatedService.support,
        sdkType: activatedService.sdkType,
        OPsetup: activatedService.OPsetup,
        OPLicenceType: activatedService.OPLicenceType,
        services: [],
        isActive: true,
        searchKey: activatedService?.service?.searchKey,
        title: serviceIdentity,
      };
    }

    identityGroup = {
      ...identityGroup,
      [serviceIdentity]: {
        ...identityGroup[serviceIdentity],
        ...config,
      },
    };
  }

  return identityGroup;
};

// fill the service config with userData
export const formatServicesLayouts = (
  clientServices: ClientService[],
  allServices: Service[]
) => {
  const response = { ...servicesConfig };
  if (clientServices && allServices) {
    Object.keys(servicesConfig).forEach((servicesKey) => {
      // @ts-ignore
      const serviceGroup = servicesConfig[servicesKey];
      if (servicesKey === "identity") {
        response[servicesKey] = _formatServiceIdentityConsumption(
          clientServices,
          allServices
        );
      } else {
        Object.keys(serviceGroup).forEach((serviceKey) => {
          // @ts-ignore
          response[servicesKey][serviceKey] = _formatServiceBySearchKey(
            serviceKey,
            clientServices,
            allServices
          );
        });
      }
    });
  }
  return response;
};

export const formatCalendarData = (
  totalCountsPerPeriod: CallsPerPeriod[],
  serviceKeyName?: string,
  typeOfCall?: string
) => {
  if (serviceKeyName) {
    serviceKeyName = listApiServices[serviceKeyName];
  }
  const consumptionPerYear: any[] = [];
  const apiCallsPerYear: any[] = [];
  const identityPerYear: any[] = [];
  // parcourt les années
  for (
    let startYear = 2020;
    startYear <= parseInt(moment(new Date()).format("YYYY"));
    startYear++
  ) {
    const consumptionPerMonths: any[] = [];
    const apiCallsPerMonths: any[] = [];
    const identityPerMonths: any[] = [];

    const currentYear = startYear.toString();
    const dateStart = moment(currentYear).startOf("year");
    const dateEnd = moment(dateStart).add(11, "month");

    // parcourt les mois
    while (dateEnd.diff(dateStart, "months") >= 0) {
      const currentMonth = dateStart.format("YYYY-MM");
      let totalApiCall = 0;
      let totalImageSize = 0;
      let totalVideoSize = 0;
      let imageApiCall = 0;
      let videoApiCall = 0;
      let totalSize = 0;
      let totalModelCreation = 0;
      let totalVector = 0;
      totalCountsPerPeriod.forEach((elem: any) => {
        if (elem.date === currentMonth) {
          if (
            elem.type === "PICTURE" &&
            (serviceKeyName ? serviceKeyName === elem.searchKey : true)
          ) {
            if (serviceKeyName === "SRV_IDENTITY") {
              if (typeOfCall === "SEARCH" && elem.typeOfCall === "SEARCH") {
                totalImageSize += parseInt(elem.size);
                imageApiCall += parseInt(elem.totalCount);
              } else if (
                typeOfCall === "RECOGNIZE" &&
                elem.typeOfCall === "RECOGNITION"
              ) {
                totalImageSize += parseInt(elem.size);
                imageApiCall += parseInt(elem.totalCount);
              } else if (
                typeOfCall === "UPSERT" &&
                (elem.typeOfCall === "CREATE" || elem.typeOfCall === "UPDATE")
              ) {
                if (elem.typeOfCall === "CREATE") {
                  totalModelCreation += parseInt(elem.totalCount);
                }
                totalImageSize += parseInt(elem.size);
                imageApiCall += parseInt(elem.totalCount);
                totalVector += parseInt(elem.nbFiles);
              }
            } else {
              if (
                !serviceKeyName &&
                (elem.typeOfCall === "CREATE" || elem.typeOfCall === "UPDATE")
              ) {
                totalVector += parseInt(elem.nbFiles);
              }
              if (elem.typeOfCall === "CREATE") {
                totalModelCreation += parseInt(elem.totalCount);
              }
              totalImageSize += parseInt(elem.size);
              imageApiCall += parseInt(elem.totalCount);
            }
          }
          if (
            elem.type === "VIDEO" &&
            (serviceKeyName ? serviceKeyName === elem.searchKey : true)
          ) {
            totalVideoSize += parseInt(elem.size);
            videoApiCall += parseInt(elem.totalCount);
          }
          totalApiCall += parseInt(elem.totalCount);
          totalSize += parseInt(elem.size);
        }
      });
      if (serviceKeyName) {
        totalApiCall = imageApiCall + videoApiCall;
        totalSize = totalImageSize + totalVideoSize;
      }
      consumptionPerMonths.push({
        month: currentMonth,
        total: totalSize,
        image: totalImageSize,
        video: totalVideoSize,
      });
      apiCallsPerMonths.push({
        month: currentMonth,
        total: totalApiCall,
        image: imageApiCall,
        video: videoApiCall,
      });
      identityPerMonths.push({
        month: currentMonth,
        model: totalModelCreation,
        vectors: totalVector,
      });
      dateStart.add(1, "month");
    }
    consumptionPerYear.push({
      year: startYear,
      months: consumptionPerMonths,
    });
    apiCallsPerYear.push({ year: startYear, months: apiCallsPerMonths });
    identityPerYear.push({ year: startYear, months: identityPerMonths });
  }
  return { consumptionPerYear, apiCallsPerYear, identityPerYear };
};

export const formatPremiseServices = (options: string[]) => {
  return options.map((option) => ({ label: option, value: option }));
};

export const getOptionsServicesFormatted = () => {
  return optionsServices.map((option) => ({ label: option, value: option }));
};

export const formatSelectServices = (t: TFunction) => {
  return [
    {
      label: t("services-management.services-service-config.all"),
      value: "all",
      searchKey: "all",
    },
    ...Object.keys(servicesConfig)
      .map((serviceKey) => ({
        label: t(`services-management.services-service-config.${serviceKey}`),
        value: serviceKey,
        searchKey: servicesAPIKey[serviceKey],
      }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      }),
  ];
};

export const getApiServices = (params: any) => {
  return params.filter(
    (service: any) =>
      !service.searchKey.includes("_OP") &&
      !service.searchKey.includes("_SDK") &&
      !service.searchKey.includes("_PREMISE")
  );
};

export const formatDashboardServices = (t: TFunction) => [
  { label: "All", value: "all" },
  ...Object.entries(servicesAPIKey)
    .map(([key, value]) => ({
      label: t(`services-management.services-service-config.${key}`),
      value: value,
    }))
    .sort(),
];

/**
 * Fill an object of formatted accounts
 * @param accounts array of account created for each type of account for the period
 * @returns formatted accounts data including total accounts, trial accounts, payable accounts, active accounts
 */
export const formatAccounts = (accounts: ClientCountElement[]): Account => {
  const formattedAccounts: Account = {
    total: 0, // basic + standard + premium + trial (not active)
    trial: 0,
    payable: 0,
    active: 0,
  };

  accounts.forEach((account) => {
    if (account.type === Plans.TRIAL) {
      formattedAccounts.trial = parseInt(account.total);
      formattedAccounts.total += parseInt(account.total);
    } else if (account.type === "ACTIVE") {
      formattedAccounts.active = parseInt(account.total);
    } else {
      formattedAccounts.payable += parseInt(account.total);
      formattedAccounts.total += parseInt(account.total);
    }
  });

  return formattedAccounts;
};

/**
 * Fill an array of account series for each month of the selected year or each day of the selected month
 * @param dataThisPeriod array of account created for each type of account for the period
 * @param name either a month or a day
 * @returns a formatted account creation data for the time period
 */
const getAccountsCreatedThisPeriod = (
  dataThisPeriod: ClientCountDateElement[],
  name: string
): AccountSerie => {
  const thisPeriodAccountSerie: AccountSerie = {
    name: name,
    basic: 0,
    standard: 0,
    premium: 0,
    trial: 0,
  };

  dataThisPeriod.forEach((elem) => {
    switch (elem.type) {
      case Plans.BASIC:
        thisPeriodAccountSerie.basic += parseInt(elem.total);
        break;
      case Plans.STANDARD:
        thisPeriodAccountSerie.standard += parseInt(elem.total);
        break;
      case Plans.PREMIUM:
        thisPeriodAccountSerie.premium += parseInt(elem.total);
        break;
      case Plans.TRIAL:
        thisPeriodAccountSerie.trial += parseInt(elem.total);
        break;
      default:
        break;
    }
  });

  return thisPeriodAccountSerie;
};

/**
 * Fill an array of account series for each month of the selected year
 * @param data array of account created for each type of account and each month of the year passed in params
 * @param year format 'YYYY'
 * @returns array of formatted accounts creation data for Recharts chart in DashboardClients
 */
export const formatYearlyCreatedAccounts = (
  data: ClientCountDateElement[],
  year: string
) => {
  const months = moment.monthsShort();
  const formattedData: AccountSerie[] = [];

  months.forEach((month) => {
    const monthInYear = moment(year).month(month).format("YYYY-MM");
    const dataThisMonth = data.filter((d) => d.date === monthInYear);
    const accountsThisMonth = getAccountsCreatedThisPeriod(
      dataThisMonth,
      month
    );

    formattedData.push(accountsThisMonth);
  });
  return formattedData;
};

/**
 * Fill an array of account series for each day of the selected month
 * @param data array of account created for each type of account and each day of the month passed in params
 * @param yearMonth format 'YYYY-MM'
 * @returns array of formatted accounts creation data for Recharts chart in DashboardClients
 */
export const formatMonthlyCreatedAccounts = (
  data: ClientCountDateElement[],
  yearMonth: string
) => {
  const numberOfDays = moment(yearMonth).daysInMonth();
  const formattedData: AccountSerie[] = [];

  for (let i = 1; i <= numberOfDays; i++) {
    const dayInMonth = moment(yearMonth).date(i);
    const dataThisDay = data.filter(
      (d) => d.date === dayInMonth.format("YYYY-MM-DD")
    );
    const accountsThisDay = getAccountsCreatedThisPeriod(
      dataThisDay,
      dayInMonth.format("dddd").replace("day", "")
    );

    formattedData.push(accountsThisDay);
  }

  return formattedData;
};

/**
 * Fill an object of formatted calls or formatted consumption to use in a Pie Chart serie (from Recharts lib)
 * @param data array of calls & consumption count for each type of data (PICTURE OR VIDEO)
 * @param series A pie chart serie to update
 * @param type 'calls' to format calls count or 'consumption' to format consumption count
 * @returns formatted pie chart series with updated values
 */
export const formatCallsAndConsumption = (
  data: ConsumptionCount[],
  series: PieChartSerie[],
  type: "calls" | "consumption"
): PieChartSerie[] => {
  series.forEach((serie) => {
    let item: ConsumptionCount | undefined;

    if (serie.name === "videos") {
      item = data.find((item) => item.type === "VIDEO");
    } else {
      item = data.find((item) => item.type === "PICTURE");
    }
    serie.value =
      type === "calls"
        ? parseInt(item?.totalCount ?? "0")
        : parseInt(item?.size ?? "0");
  });

  return series;
};

/**
 * Fill an object of formatted calls or formatted consumption by service to use in a Pie Chart serie (from Recharts lib)
 * @param data array of calls & consumption count by service
 * @param series A pie chart serie to update
 * @param type 'calls' to format calls count or 'consumption' to format consumption count
 * @returns formatted pie chart series with updated values
 */
export const formatCallsAndConsumptionByService = (
  data: ConsumptionByService[],
  series: PieChartSerie[],
  type: "calls" | "consumption"
): PieChartSerie[] => {
  series.forEach((serie) => {
    const item = data.find((item) => item.service === serie.name);

    serie.value =
      type === "calls"
        ? parseInt(item?.totalCount ?? "0")
        : parseInt(item?.size ?? "0");
  });

  return series;
};

/**
 * Create a formatted array to be used in a pie chart.
 * For each service :
 * - name: service name
 * - value: 0 as initial value
 * - cellColor: service color
 */
export const formatInitialCallsAndConsumptionByService = (): PieChartSerie[] =>
  Object.entries(servicesColors).map(([key, value]) => ({
    name: key,
    value: 0,
    cellColor: value,
  }));

/**
 * Fill an array of consumption series for each month of the selected year or for each day of the selected month
 * @param dataThisPeriod array of consumption for the period
 * @param name either a month or a day
 * @returns a formatted account creation data for the time period
 */
const getConsumptionThisPeriod = (
  dataThisPeriod: ConsumptionByPeriod[],
  name: string
): ConsumptionSerie => {
  const thisPeriodConsumptionSerie: ConsumptionSerie = {
    name: name,
    calls: 0,
    consumption: 0,
  };

  dataThisPeriod.forEach((elem) => {
    thisPeriodConsumptionSerie.calls += parseInt(elem.totalCount);
    thisPeriodConsumptionSerie.consumption += parseInt(elem.size);
  });

  thisPeriodConsumptionSerie.consumption = getMegaByteValue(
    thisPeriodConsumptionSerie.consumption
  );

  return thisPeriodConsumptionSerie;
};

/**
 * Fill an array of consumption series for each month of the selected year
 * @param data array of consumption for each month of the year passed in params
 * @param year format 'YYYY'
 * @returns array of formatted consumption data for Recharts chart in DashboardConsumption
 */
export const formatYearlyConsumption = (
  data: ConsumptionByPeriod[],
  year: string
): ConsumptionSerie[] => {
  const months = moment.monthsShort();
  const formattedData: ConsumptionSerie[] = [];

  months.forEach((month) => {
    const monthInYear = moment(year).month(month).format("YYYY-MM");
    const dataThisMonth = data.filter((d) => d.date === monthInYear);
    const consumptionThisMonth = getConsumptionThisPeriod(dataThisMonth, month);

    formattedData.push(consumptionThisMonth);
  });

  return formattedData;
};

/**
 * Fill an array of consumption series for each day of the selected month
 * @param data array of consumption for each day of the month passed in params
 * @param yearMonth format 'YYYY-MM'
 * @returns array of formatted consumption data for Recharts chart in DashboardConsumption
 */
export const formatMonthlyConsumption = (
  data: ConsumptionByPeriod[],
  yearMonth: string
): ConsumptionSerie[] => {
  const numberOfDays = moment(yearMonth).daysInMonth();
  const formattedData: ConsumptionSerie[] = [];

  for (let i = 1; i <= numberOfDays; i++) {
    const dayInMonth = moment(yearMonth).date(i);
    const dataThisDay = data.filter(
      (d) => d.date === dayInMonth.format("YYYY-MM-DD")
    );
    const consumptionThisDay = getConsumptionThisPeriod(
      dataThisDay,
      dayInMonth.format("dddd").replace("day", "")
    );

    formattedData.push(consumptionThisDay);
  }

  return formattedData;
};

//TODO REMOVE
// /**
//  * Fill an array of heatmap series for each day of the selected year
//  * @param data array of consumption for each day of the year passed in params
//  * @param year format 'YYYY'
//  * @returns array of formatted consumption data for CustomHeatmapChart in Dashboard
//  */
// export const formatConsumptionHeatmapData = (data: ConsumptionByPeriod[], year: string): HeatMapSerie[] => {
//     const startDate = moment(year).startOf('year');
//     const endDate = moment(year).endOf('year');
//     const days = Math.abs(startDate.diff(endDate, 'days')) + 1;
//     const dayFormat = 'DD-MM-YYYY';

//     const formattedData: HeatMapSerie[] = Array.from(new Array(days)).map((_, index) => {
//         const currentDay = moment(startDate).add(index, 'day');
//         const dataOfTheDay = data.find((dayData) => moment(dayData.date).format(dayFormat) === currentDay.format(dayFormat));
//         const valueOfTheDay = dataOfTheDay ? parseInt(dataOfTheDay.totalCount) : 0;

//         return {
//             date: currentDay,
//             value: valueOfTheDay,
//         };
//     });

//     return formattedData;
// };

const getIdentityAggregatesPerDate = (
  aggregatsData: AggregationConsumption,
  searchKey?: string
) => {
  if (
    aggregatsData.identity_create_image_api_calls &&
    aggregatsData.identity_create_image_consumption
  ) {
  }
  if (
    aggregatsData.identity_recognition_image_api_calls &&
    aggregatsData.identity_recognition_image_consumption
  ) {
  }
  if (
    aggregatsData.identity_search_image_api_calls &&
    aggregatsData.identity_search_image_consumption
  ) {
  }
};

/**
 * Get aggregates from aggregat types / date / searchkey and add daily consumption to the corresponding date
 * @param data an array of aggregats
 * @param aggregatsType the type of aggregat: 'apiCalls' | 'consumption'
 * @param date the date to get the aggregate
 * @param searchKey the searchKey to get the aggregates
 * @returns
 */
const getAggregatesPerAggregatTypeAndMonth = (
  aggregatsData: AggregationConsumption[],
  dailyData: AggregationConsumption | undefined,
  aggregatsType: "apiCalls" | "consumption" | "identity",
  date: string,
  searchKey?: string,
  identityData?: {
    totalCountsPerPeriod: Record<
      string,
      { identityCount: number; vectorCount: number }
    >;
  }
) => {
  let totalImage = 0;
  let totalVideo = 0;
  let sum = 0;
  let totalVectors = 0;
  let totalModels = 0;

  let currentMonthData: AggregationConsumption | undefined = aggregatsData.find(
    (d) => d.period === date
  );
  let currentMonthIdentity = identityData?.totalCountsPerPeriod[date];
  if (aggregatsData && currentMonthData) {
    if (aggregatsType === "apiCalls") {
      totalImage = currentMonthData.total_image_api_calls
        ? parseInt(currentMonthData.total_image_api_calls)
        : 0;
      if (
        !searchKey ||
        searchKey === "SRV_ANM" ||
        searchKey === "SRV_WATERMARK"
      ) {
        totalVideo = currentMonthData.total_video_api_calls
          ? parseInt(currentMonthData.total_video_api_calls)
          : 0;
        sum = totalImage + totalVideo;
      } else {
        sum = totalImage;
      }
    }
    if (aggregatsType === "consumption") {
      totalImage = currentMonthData.total_image_consumption
        ? parseInt(currentMonthData.total_image_consumption)
        : 0;
      if (
        !searchKey ||
        searchKey === "SRV_ANM" ||
        searchKey === "SRV_WATERMARK"
      ) {
        totalVideo = currentMonthData.total_video_consumption
          ? parseInt(currentMonthData.total_video_consumption)
          : 0;
        sum = totalImage + totalVideo;
      } else {
        sum = totalImage;
      }
    }
  }
  // get numbers of identities and vectors for current month
  if (aggregatsType === "identity" && currentMonthIdentity) {
    totalVectors = currentMonthIdentity.vectorCount;
    totalModels = currentMonthIdentity.identityCount;
  }
  if (dailyData && moment(dailyData.period, "YYYY-MM").isSame(date)) {
    if (aggregatsType === "apiCalls") {
      const daily_total_image_apiCalls = dailyData.total_image_api_calls
        ? parseInt(dailyData.total_image_api_calls)
        : 0;
      const daily_total_video_apiCalls = dailyData.total_video_api_calls
        ? parseInt(dailyData.total_video_api_calls)
        : 0;
      totalImage += daily_total_image_apiCalls;
      totalVideo += daily_total_video_apiCalls;
      sum = totalImage + totalVideo;
    }
    if (aggregatsType === "consumption") {
      const daily_total_image_consumption = dailyData.total_image_consumption
        ? parseInt(dailyData.total_image_consumption)
        : 0;
      const daily_total_video_consumption = dailyData.total_video_consumption
        ? parseInt(dailyData.total_video_consumption)
        : 0;
      totalImage += daily_total_image_consumption;
      totalVideo += daily_total_video_consumption;
      sum = totalImage + totalVideo;
    }
    if (aggregatsType === "identity") {
    }
  }

  return {
    month: date,
    totalImage,
    totalVideo,
    sum,
    totalVectors,
    totalModels,
  };
};

const getTotalAggregates = (
  data: AggregationConsumption[],
  dailyData: AggregationConsumption | undefined,
  aggregatsType: "apiCalls" | "consumption",
  searchKey?: string
) => {
  let totalImage = 0;
  let totalVideo = 0;
  let sum = 0;
  for (let period of data) {
    if (aggregatsType === "apiCalls") {
      const total_image_api_calls = period.total_image_api_calls
        ? parseInt(period.total_image_api_calls)
        : 0;
      const total_video_api_calls = period.total_video_api_calls
        ? parseInt(period.total_video_api_calls)
        : 0;
      // always sum image for each services
      totalImage += total_image_api_calls;
      // SRV_ANM and SRV_WATERMARK have video to sum
      if (
        !searchKey ||
        searchKey === "SRV_ANM" ||
        searchKey === "SRV_WATERMARK"
      ) {
        totalVideo += total_video_api_calls;
        sum += total_image_api_calls + total_video_api_calls;
      } else {
        sum += totalImage;
      }
    }
    if (aggregatsType === "consumption") {
      const total_image_consumption = period.total_image_consumption
        ? parseInt(period.total_image_consumption)
        : 0;
      const total_video_consumption = period.total_video_consumption
        ? parseInt(period.total_video_consumption)
        : 0;
      // always sum image for each services
      totalImage += total_image_consumption;
      if (
        !searchKey ||
        searchKey === "SRV_ANM" ||
        searchKey === "SRV_WATERMARK"
      ) {
        totalVideo += total_video_consumption;
        sum += total_image_consumption + total_video_consumption;
      } else {
        sum += totalImage;
      }
    }
  }
  if (dailyData) {
    if (aggregatsType === "apiCalls") {
      const daily_total_image_apiCalls = dailyData.total_image_api_calls
        ? parseInt(dailyData.total_image_api_calls)
        : 0;
      const daily_total_video_apiCalls = dailyData.total_video_api_calls
        ? parseInt(dailyData.total_video_api_calls)
        : 0;
      totalImage += daily_total_image_apiCalls;
      totalVideo += daily_total_video_apiCalls;
      sum = totalImage + totalVideo;
    }
    if (aggregatsType === "consumption") {
      const daily_total_image_consumption = dailyData.total_image_consumption
        ? parseInt(dailyData.total_image_consumption)
        : 0;
      const daily_total_video_consumption = dailyData.total_video_consumption
        ? parseInt(dailyData.total_video_consumption)
        : 0;
      totalImage += daily_total_image_consumption;
      totalVideo += daily_total_video_consumption;
      sum = totalImage + totalVideo;
    }
  }

  return {
    totalImage,
    totalVideo,
    sum,
  };
};

const prepareApiCallsAndConsumptionData = (
  prevData: AggregationConsumption[],
  dailyData: AggregationConsumption | undefined,
  aggregatsType: "apiCalls" | "consumption",
  searchKey?: string
): { total: TotalAggregates; monthly: MonthlyAggregates } => ({
  total: getTotalAggregates(prevData, dailyData, aggregatsType, searchKey),
  monthly: getAggregatesPerAggregatTypeAndMonth(
    prevData,
    dailyData,
    aggregatsType,
    moment().format("YYYY-MM"),
    searchKey
  ),
});

const initYearDataCalendar = (year: string) => {
  return {
    year: year,
    apiCalls: {
      totalVideo: 0,
      totalImage: 0,
      sum: 0,
      months: [],
    },
    consumption: {
      totalVideo: 0,
      totalImage: 0,
      sum: 0,
      months: [],
    },
    identity: {
      totalModels: 0,
      totalVectors: 0,
      months: [],
    },
  };
};

const prepareClientCalendar = (
  prevData: AggregationConsumption[],
  dailyData: AggregationConsumption | undefined,
  searchKey?: string,
  identityData?: {
    totalCountsPerPeriod: Record<
      string,
      { identityCount: number; vectorCount: number }
    >;
  }
): Array<YearlyAggregatesCalendarData> => {
  let arrayYears: Array<YearlyAggregatesCalendarData> = [];
  for (
    let startYear = 2020;
    startYear <= parseInt(moment(new Date()).format("YYYY"));
    startYear++
  ) {
    const currentYear = startYear.toString();
    const dateStart = moment(currentYear).startOf("year");
    const dateEnd = moment(dateStart).add(11, "month");
    let yearData: YearlyAggregatesCalendarData =
      initYearDataCalendar(currentYear);

    while (dateEnd.diff(dateStart, "months") >= 0) {
      const currentMonth = dateStart.format("YYYY-MM");

      const monthlyApiCalls = getAggregatesPerAggregatTypeAndMonth(
        prevData,
        dailyData,
        "apiCalls",
        currentMonth,
        searchKey
      );
      yearData.apiCalls.totalImage += monthlyApiCalls.totalImage;
      yearData.apiCalls.totalVideo += monthlyApiCalls.totalVideo;
      yearData.apiCalls.sum += monthlyApiCalls.sum;
      yearData.apiCalls.months.push(monthlyApiCalls);

      const monthlyConsumption = getAggregatesPerAggregatTypeAndMonth(
        prevData,
        dailyData,
        "consumption",
        currentMonth,
        searchKey
      );
      yearData.consumption.totalImage += monthlyConsumption.totalImage;
      yearData.consumption.totalVideo += monthlyConsumption.totalVideo;
      yearData.consumption.sum += monthlyConsumption.sum;
      yearData.consumption.months.push(monthlyConsumption);

      const monthlyIdentities = getAggregatesPerAggregatTypeAndMonth(
        prevData,
        dailyData,
        "identity",
        currentMonth,
        searchKey,
        identityData
      );
      yearData.identity.totalModels += monthlyIdentities.totalModels;
      yearData.identity.totalVectors += monthlyIdentities.totalVectors;
      yearData.identity.months.push(monthlyIdentities);
      dateStart.add(1, "month");
    }
    arrayYears.push(yearData);
  }
  return arrayYears;
};

export const formatClientConsumptionAggregates = (
  aggregates: ClientAggregationConsumption,
  searchKey?: string,
  identityData?: {
    totalCountsPerPeriod: Record<
      string,
      { identityCount: number; vectorCount: number }
    >;
  }
) => {
  const prevData = aggregates.prevAggregates;
  const dailyData = aggregates.dailyAggregates;
  // data for the api calls block
  let apiCalls = prepareApiCallsAndConsumptionData(
    prevData,
    dailyData,
    "apiCalls",
    searchKey
  );
  // data for the consumption block
  let consumption = prepareApiCallsAndConsumptionData(
    prevData,
    dailyData,
    "consumption"
  );
  // data for the calendar section
  let arrayYears = prepareClientCalendar(
    prevData,
    dailyData,
    searchKey,
    identityData
  );
  return {
    apiCallAggregates: apiCalls,
    consumptionAggregates: consumption,
    calendarAggregates: arrayYears,
  };
};

export const formatClientTrialAggregatesBlockData = (
  trialAggregates: ClientAggregationConsumption,
  searchKey?: string
) => {
  const prevData = trialAggregates.prevAggregates;
  const dailyData = trialAggregates.dailyAggregates;
  // data for the api calls block
  let apiCalls = prepareApiCallsAndConsumptionData(
    prevData,
    dailyData,
    "apiCalls",
    searchKey
  );
  // data for the consumption block
  let consumption = prepareApiCallsAndConsumptionData(
    prevData,
    dailyData,
    "consumption"
  );
  return {
    trialApiCallAggregates: apiCalls,
    trialConsumptionAggregates: consumption,
  };
};

const getClientConsumptionThisPeriod = (
  data: AggregationConsumption[],
  dailyData: AggregationConsumption | undefined,
  month: string
) => {
  const result = data.reduce(
    (acc, curr) => {
      const total_image_api_calls = curr.total_image_api_calls
        ? parseInt(curr.total_image_api_calls)
        : 0;
      const total_video_api_calls = curr.total_video_api_calls
        ? parseInt(curr.total_video_api_calls)
        : 0;
      const calls = acc.calls + total_image_api_calls + total_video_api_calls;

      const total_image_consumption = curr.total_image_consumption
        ? parseInt(curr.total_image_consumption)
        : 0;
      const total_video_consumption = curr.total_video_consumption
        ? parseInt(curr.total_video_consumption)
        : 0;
      const consumption =
        acc.consumption + total_image_consumption + total_video_consumption;

      return { calls: calls, consumption: consumption, name: month };
    },
    { calls: 0, consumption: 0, name: month }
  );
  result.consumption = getMegaByteValue(result.consumption);
  return result;
};

const groupDataPerMonths = (
  data: AggregationConsumption[],
  year: string,
  dailyData?: AggregationConsumption
) => {
  const months = moment.monthsShort();
  const formattedData: ConsumptionSerie[] = [];
  months.forEach((month) => {
    const monthInYear = moment(year).month(month).format("YYYY-MM");
    const dataThisMonth = data.filter(
      (d) => moment(d.period, "YYYY-MM-DD").format("YYYY-MM") === monthInYear
    );
    if (
      dailyData &&
      dailyData?.period &&
      monthInYear === moment(dailyData.period).format("YYYY-MM")
    ) {
      dataThisMonth.push(dailyData);
    }
    formattedData.push(
      getClientConsumptionThisPeriod(dataThisMonth, dailyData, month)
    );
  });
  return formattedData;
};

const getClientConsumptionThisDay = (
  dataThisDay: AggregationConsumption | undefined,
  dailyData: AggregationConsumption | undefined,
  day: string
) => {
  const data = {
    calls: 0,
    consumption: 0,
    name: moment(day).format("ddd"),
  };
  const total_image_api_calls = dataThisDay?.total_image_api_calls
    ? parseInt(dataThisDay.total_image_api_calls)
    : 0;
  const total_video_api_calls = dataThisDay?.total_video_api_calls
    ? parseInt(dataThisDay.total_video_api_calls)
    : 0;
  const total_image_consumption = dataThisDay?.total_image_consumption
    ? parseInt(dataThisDay.total_image_consumption)
    : 0;
  const total_video_consumption = dataThisDay?.total_video_consumption
    ? parseInt(dataThisDay.total_video_consumption)
    : 0;

  data.calls = total_image_api_calls + total_video_api_calls;
  data.consumption = total_image_consumption + total_video_consumption;

  if (dailyData && dailyData?.period === day) {
    const daily_total_image_api_calls = dailyData?.total_image_api_calls
      ? parseInt(dailyData.total_image_api_calls)
      : 0;
    const daily_total_video_api_calls = dailyData?.total_video_api_calls
      ? parseInt(dailyData.total_video_api_calls)
      : 0;
    const daily_total_image_consumption = dailyData?.total_image_consumption
      ? parseInt(dailyData.total_image_consumption)
      : 0;
    const daily_total_video_consumption = dailyData?.total_video_consumption
      ? parseInt(dailyData.total_video_consumption)
      : 0;
    data.calls += daily_total_image_api_calls + daily_total_video_api_calls;
    data.consumption +=
      daily_total_image_consumption + daily_total_video_consumption;
  }
  data.consumption = getMegaByteValue(data.consumption);
  return data;
};

const getDailyDataFromMonth = (
  data: AggregationConsumption[],
  year: string,
  month: string,
  dailyData?: AggregationConsumption
) => {
  const momentMonth = moment(`${year}-${month}`);
  const monthInYearStr = momentMonth.format("YYYY-MM");
  const dataPerMonth = data.filter((d) => {
    return moment(d.period, "YYYY-MM-DD").format("YYYY-MM") === monthInYearStr;
  });
  const days: ConsumptionSerie[] = [];
  for (let i = 1; i < momentMonth.daysInMonth() + 1; i++) {
    const currentDayStr = moment(`${year}-${month}-${i}`).format("YYYY-MM-DD");
    const dataPerDay = dataPerMonth.find((d) => {
      return d.period === currentDayStr;
    });
    days.push(
      getClientConsumptionThisDay(dataPerDay, dailyData, currentDayStr)
    );
  }
  return days;
};

export const formatClientChartsAggregates = (
  aggregates: ClientAggregationConsumption,
  year: string,
  month: string
) => {
  const prevData = aggregates.prevAggregates;
  const dailyData = aggregates?.dailyAggregates;

  const dataPerMonth = groupDataPerMonths(prevData, year, dailyData);
  const dataPerDay = getDailyDataFromMonth(prevData, year, month, dailyData);

  return {
    chartsPerMonth: dataPerMonth,
    chartsPerDay: dataPerDay,
  };
};

const groupAggregatesPerSearchKey = (
  aggregates: AggregationConsumption,
  period: string
) => {
  const searchKeys = Object.values(servicesAPIKey);
  let array = [];
  for (const searchKey of searchKeys) {
    if (searchKey === "SRV_ANM") {
      array.push({
        period,
        searchKey: "SRV_ANM",
        apiCalls: {
          anonymization_image_api_calls: parseInt(
            aggregates?.anonymization_image_api_calls ?? "0"
          ),
          anonymization_video_api_calls: parseInt(
            aggregates?.anonymization_video_api_calls ?? "0"
          ),
        },
        consumption: {
          anonymization_image_consumption: parseInt(
            aggregates?.anonymization_image_consumption ?? "0"
          ),
          anonymization_video_consumption: parseInt(
            aggregates?.anonymization_video_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_FAKE_DETECTION") {
      array.push({
        period,
        searchKey: "SRV_FAKE_DETECTION",
        apiCalls: {
          fake_detection_image_api_calls: parseInt(
            aggregates?.fake_detection_image_api_calls ?? "0"
          ),
        },
        consumption: {
          fake_detection_image_consumption: parseInt(
            aggregates?.fake_detection_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_CNG") {
      array.push({
        period,
        searchKey: "SRV_CNG",
        apiCalls: {
          congestion_image_api_calls: parseInt(
            aggregates?.congestion_image_api_calls ?? "0"
          ),
        },
        consumption: {
          congestion_image_consumption: parseInt(
            aggregates?.congestion_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_FACE") {
      array.push({
        period,
        searchKey: "SRV_FACE",
        apiCalls: {
          facelytics_image_api_calls: parseInt(
            aggregates?.facelytics_image_api_calls ?? "0"
          ),
        },
        consumption: {
          facelytics_image_consumption: parseInt(
            aggregates?.facelytics_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_SOL") {
      array.push({
        period,
        searchKey: "SRV_SOL",
        apiCalls: {
          soiling_image_api_calls: parseInt(
            aggregates?.soiling_image_api_calls ?? "0"
          ),
        },
        consumption: {
          soiling_image_consumption: parseInt(
            aggregates?.soiling_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_WATERMARK") {
      array.push({
        period,
        searchKey: "SRV_WATERMARK",
        apiCalls: {
          watermark_image_api_calls: parseInt(
            aggregates?.watermark_image_api_calls ?? "0"
          ),
          watermark_video_api_calls: parseInt(
            aggregates?.watermark_video_api_calls ?? "0"
          ),
        },
        consumption: {
          watermark_image_consumption: parseInt(
            aggregates?.watermark_image_consumption ?? "0"
          ),
          watermark_video_consumption: parseInt(
            aggregates?.watermark_video_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_ORIENTATION") {
      array.push({
        period,
        searchKey: "SRV_ORIENTATION",
        apiCalls: {
          orientation_image_api_calls: parseInt(
            aggregates?.orientation_image_api_calls ?? "0"
          ),
        },
        consumption: {
          orientation_image_consumption: parseInt(
            aggregates?.orientation_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_VHC_PDS_DETECT") {
      array.push({
        period,
        searchKey: "SRV_VHC_PDS_DETECT",
        apiCalls: {
          vehicules_and_pedestrian_image_api_calls: parseInt(
            aggregates?.vehicules_and_pedestrian_image_api_calls ?? "0"
          ),
        },
        consumption: {
          vehicules_and_pedestrian_image_consumption: parseInt(
            aggregates?.vehicules_and_pedestrian_image_consumption ?? "0"
          ),
        },
      });
    }
    if (searchKey === "SRV_IDENTITY") {
      array.push({
        period,
        searchKey: "SRV_IDENTITY",
        apiCalls: {
          identity_create_image_api_calls: parseInt(
            aggregates?.identity_create_image_api_calls ?? "0"
          ),
          identity_recognition_image_api_calls: parseInt(
            aggregates?.identity_recognition_image_api_calls ?? "0"
          ),
          identity_search_image_api_calls: parseInt(
            aggregates?.identity_search_image_api_calls ?? "0"
          ),
          identity_update_image_api_calls: parseInt(
            aggregates?.identity_search_image_api_calls ?? "0"
          ),
        },
        consumption: {
          identity_create_image_consumption: parseInt(
            aggregates?.identity_create_image_consumption ?? "0"
          ),
          identity_recognition_image_consumption: parseInt(
            aggregates?.identity_recognition_image_consumption ?? "0"
          ),
          identity_search_image_consumption: parseInt(
            aggregates?.identity_search_image_consumption ?? "0"
          ),
          identity_update_image_consumption: parseInt(
            aggregates?.identity_update_image_consumption ?? "0"
          ),
        },
      });
    }
  }
  return array;
};

const sum = (data: any) => {
  return Object.values(data).reduce((a: any, b: any) => a + b, 0);
};

export const formatServiceRepartitionPieChart = (
  periodicData: AggregationConsumption[],
  dailyData?: AggregationConsumption
) => {
  let groupedBySearchKey = [];
  const result = {
    SRV_ANM: { consumption: 0, apiCalls: 0, color: "" },
    SRV_FACE: { consumption: 0, apiCalls: 0, color: "" },
    SRV_FAKE_DETECTION: { consumption: 0, apiCalls: 0, color: "" },
    SRV_CNG: { consumption: 0, apiCalls: 0, color: "" },
    SRV_SOL: { consumption: 0, apiCalls: 0, color: "" },
    SRV_WATERMARK: { consumption: 0, apiCalls: 0, color: "" },
    SRV_ORIENTATION: { consumption: 0, apiCalls: 0, color: "" },
    SRV_VHC_PDS_DETECT: { consumption: 0, apiCalls: 0, color: "" },
    SRV_IDENTITY: { consumption: 0, apiCalls: 0, color: "" },
  };
  if (dailyData) {
    groupedBySearchKey.push(
      groupAggregatesPerSearchKey(dailyData, dailyData.period)
    );
  }
  for (const period of periodicData) {
    const data = groupAggregatesPerSearchKey(period, period.period);
    groupedBySearchKey.push(data);
  }
  for (const period of groupedBySearchKey) {
    for (const perService of period) {
      (result as any)[perService.searchKey].consumption += sum(
        perService.consumption
      );
      (result as any)[perService.searchKey].apiCalls += sum(
        perService.apiCalls
      );
      (result as any)[perService.searchKey].color =
        servicesColors[perService.searchKey];
    }
  }
  return result;
};

export const COLORS_HEATMAP = {
  COLOR_0: `white`,
  COLOR_1: `rgba(3, 160, 3, ${1 / 7})`,
  COLOR_2: `rgba(3, 160, 3, ${2 / 7})`,
  COLOR_3: `rgba(3, 160, 3, ${3 / 7})`,
  COLOR_4: `rgba(3, 160, 3, ${4 / 7})`,
  COLOR_5: `rgba(3, 160, 3, ${5 / 7})`,
  COLOR_6: `rgba(3, 160, 3, ${6 / 7})`,
  COLOR_7: `rgba(3, 160, 3, ${1})`,
};

const getCellColor = (
  value: number,
  yearAverage: number,
  mustBeBlanked: boolean
) => {
  if (mustBeBlanked) {
    return COLORS_HEATMAP.COLOR_0;
  }
  // supérieur à 50% de la moyenne
  if (Math.round(value) > yearAverage + (yearAverage * 50) / 100) {
    return COLORS_HEATMAP.COLOR_7;
  }
  // entre 30% et 50% de la moyenne
  if (
    Math.round(value) > yearAverage + (yearAverage * 30) / 100 &&
    Math.round(value) < yearAverage + (yearAverage * 50) / 100
  ) {
    return COLORS_HEATMAP.COLOR_6;
  }
  // entre 10% et 30% de la moyenne
  if (
    Math.round(value) > yearAverage + (yearAverage * 10) / 100 &&
    Math.round(value) < yearAverage + (yearAverage * 30) / 100
  ) {
    return COLORS_HEATMAP.COLOR_5;
  }
  // entre -10% et 10% de la moyenne
  if (
    Math.round(value) > yearAverage - (yearAverage * 10) / 100 &&
    Math.round(value) < yearAverage + (yearAverage * 10) / 100
  ) {
    return COLORS_HEATMAP.COLOR_4;
  }
  // entre -10% et -30% de la moyenne
  if (
    Math.round(value) < yearAverage - (yearAverage * 10) / 100 &&
    Math.round(value) > yearAverage - (yearAverage * 30) / 100
  ) {
    return COLORS_HEATMAP.COLOR_3;
  }
  // entre -30% et -50% de la moyenne
  if (
    Math.round(value) < yearAverage - (yearAverage * 30) / 100 &&
    Math.round(value) > yearAverage - (yearAverage * 50) / 100
  ) {
    return COLORS_HEATMAP.COLOR_2;
  }
  // inferieur à -50% de la moyenne
  if (Math.round(value) < yearAverage - (yearAverage * 50) / 100) {
    return COLORS_HEATMAP.COLOR_1;
  }
  return COLORS_HEATMAP.COLOR_0;
};

type HeatMapData = {
  data: HeatMapSerie[];
  legendData: { average: number };
};

export type TypeOfHeatmap = {
  CONSUMPTION: HeatMapData;
  API_CALLS: HeatMapData;
};

export const prepareHeatMapData = (
  data: ConsumptionCell[],
  year: string
): TypeOfHeatmap => {
  const startDate = moment(year).startOf("year");
  const endDate = moment(year).endOf("year");
  const currentDay = moment();
  const days = Math.abs(startDate.diff(endDate, "days")) + 1;
  const maxDateToFill =
    moment(year).year() === moment().year() ? currentDay : endDate;
  const daysForAverage =
    moment(year).year() === moment().year()
      ? Math.abs(startDate.diff(currentDay, "days")) + 1
      : days;
  const dayFormat = "DD-MM-YYYY";

  let totalApiCalls = 0;
  let totalConsumption = 0;
  let result: TypeOfHeatmap = {
    CONSUMPTION: { data: [], legendData: { average: 0 } },
    API_CALLS: { data: [], legendData: { average: 0 } },
  };
  const arrayDays = Array.from(new Array(days));
  for (const day in arrayDays) {
    const currentDay = moment(startDate).add(parseInt(day), "day");
    const dataOfTheDay = data.find(
      (dayData) =>
        moment(dayData.period).format(dayFormat) ===
        currentDay.format(dayFormat)
    );
    const apiCallsOfTheDay = dataOfTheDay
      ? dataOfTheDay.total_image_api_calls + dataOfTheDay.total_video_api_calls
      : 0;
    const consumptionOfTheDay = dataOfTheDay
      ? dataOfTheDay.total_image_consumption +
        dataOfTheDay.total_video_consumption
      : 0;
    totalApiCalls += apiCallsOfTheDay;
    totalConsumption += consumptionOfTheDay;
    result.CONSUMPTION.data.push({
      color: "",
      date: currentDay,
      value: consumptionOfTheDay,
    });
    result.API_CALLS.data.push({
      color: "",
      date: currentDay,
      value: apiCallsOfTheDay,
    });
  }
  let apiCallAverage = totalApiCalls / daysForAverage;
  let consumptionAverage = totalConsumption / daysForAverage;
  for (let day in arrayDays) {
    result.CONSUMPTION.data[day].color = getCellColor(
      result.CONSUMPTION.data[day].value as number,
      Math.round(consumptionAverage),
      result.CONSUMPTION.data[day].date.isAfter(maxDateToFill)
    );
    result.CONSUMPTION.data[day].value = convertSize(
      result.CONSUMPTION.data[day].value as number
    );
    result.API_CALLS.data[day].color = getCellColor(
      result.API_CALLS.data[day].value as number,
      Math.round(apiCallAverage),
      result.API_CALLS.data[day].date.isAfter(maxDateToFill)
    );
  }
  result.API_CALLS.legendData.average = apiCallAverage;
  result.CONSUMPTION.legendData.average = consumptionAverage;
  return result;
};

export const insertDailyDataIntoPeriodicDataForServiceUsageSection = (
  periodicsData: AggregationConsumption[],
  granularity: string,
  periodFilter?: string,
  dailyData?: AggregationConsumption
) => {
  const initRow = () => ({
    total_image_api_calls: 0,
    total_video_api_calls: 0,
    total_image_consumption: 0,
    total_video_consumption: 0,
    period: "",
  });

  let data = [];

  for (let index in periodicsData) {
    const tmp = initRow();
    tmp["total_image_api_calls"] = parseInt(
      periodicsData[index].total_image_api_calls ?? "0"
    );
    tmp["total_video_api_calls"] = parseInt(
      periodicsData[index].total_video_api_calls ?? "0"
    );
    tmp["total_image_consumption"] = parseInt(
      periodicsData[index].total_image_consumption ?? "0"
    );
    tmp["total_video_consumption"] = parseInt(
      periodicsData[index].total_video_consumption ?? "0"
    );
    tmp["period"] = periodicsData[index].period;
    if (
      granularity === "month" &&
      dailyData &&
      moment(periodicsData[index].period).format("YYYY-MM") ===
        moment(dailyData.period).format("YYYY-MM")
    ) {
      tmp.total_image_api_calls += parseInt(
        dailyData.total_image_api_calls ?? "0"
      );
      tmp.total_video_api_calls += parseInt(
        dailyData?.total_video_api_calls ?? "0"
      );
      tmp.total_image_consumption += parseInt(
        dailyData.total_image_consumption ?? "0"
      );
      tmp.total_video_consumption += parseInt(
        dailyData?.total_video_consumption ?? "0"
      );
    }
    data.push(tmp);
  }
  if (
    granularity === "day" &&
    dailyData &&
    moment(periodFilter).year() === moment(dailyData.period).year()
  ) {
    data.push({
      total_image_api_calls: parseInt(dailyData.total_image_api_calls ?? "0"),
      total_video_api_calls: parseInt(dailyData?.total_video_api_calls ?? "0"),
      total_image_consumption: parseInt(
        dailyData.total_image_consumption ?? "0"
      ),
      total_video_consumption: parseInt(
        dailyData?.total_video_consumption ?? "0"
      ),
      period: dailyData?.period,
    });
  }
  return data;
};
