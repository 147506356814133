import React from 'react';
import 'assets/scss/button.scss';
import { ReactComponent as DeleteSvg } from 'assets/icons/icon-dustbin.svg';
import { ReactComponent as FilterSvg } from 'assets/icons/icon-filter2.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/icon-plus.svg';
import { ReactComponent as EditSvg } from 'assets/icons/icon-edit.svg';

interface IButton {
    title?: string,
    type?: 'submit' | 'button' | 'reset',
    buttonType?: 'delete' | 'filter' | 'add' | 'edit',
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    className?: string,
    disabled?: boolean
}

const typeIcon = {
    delete: <DeleteSvg fill="#702D6A" />,
    filter: <FilterSvg fill='#702D6A' />,
    add: <PlusSvg fill="#FFFFFF" />,
    edit: <EditSvg fill="#FFFFFF" />
};

const typeIClass = {
    delete: 'icon-delete',
    filter: 'icon-filter',
    add: 'icon-add',
    edit: 'icon-edit'
};

const Button = ({
    title = '',
    type = 'submit',
    buttonType,
    onClick,
    disabled = false,
    className = ''
}: IButton) => {
    return (
        <button
            disabled={disabled}
            className={className}
            onClick={onClick}
            type={type}>
            {buttonType &&
                <i className={typeIClass[buttonType]}>
                    {typeIcon[buttonType]}
                </i>
            }
            {title}
        </button>
    );
};

export default Button;
